@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Roboto:400,600,700|Calistoga:400');

body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  max-width: 1800px;
}

#root {
  width: 100%;
  max-width: 1800px;
}
